
import { Component, Prop } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Permission } from '@/enums/Permission';
import Article from '@/views/article/Article.vue';
import _ from 'lodash';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';

@Component({
  components: { VTable },
})
export default class OptionTable extends mixins(Notification) {
  @Prop({ type: Array, required: true }) public items!: OptionGroup[];
  @Prop({ type: Number, required: true }) public total!: number;

  public $refs!: {
    vTable: InstanceType<typeof VTable> & { vTable: any };
  };

  get tableItems(): OptionGroup[] {
    const options: OptionGroup[] = [...this.items];

    for (const option of options) {
      if (!option.hasOwnProperty('visible')) {
        option.visible = true;
      }
    }

    return options;
  }

  get isFilter(): boolean {
    return Object.values(this.searchOptions).some((v) => !!v);
  }

  public deleteSelectableDialog = false;
  public headers: ColumnHeader[] = headers;
  public searchOptions = {
    search: '',
    filterOptions: false,
  };

  public unselectAll(event: boolean) {
    if (!event) {
      this.$refs.vTable.deselectAll();
    }
  }

  public onSearchUpdate = _.debounce(() => {
    this.$refs.vTable.resetPagination();
    this.$emit('search', this.searchOptions);
    const query = Object.assign({}, this.$route.query);
    Object.assign(query, this.searchOptions);
    if (this.searchOptions.search && this.searchOptions.search.length > 0) {
      this.$router.replace({ query });
    } else {
      delete query.search;
      this.$router.replace({ query });
    }
  }, 500);

  private maxArticle: number = 3;

  public created() {
    this.searchOptions.search = (this.$route.query.search as string) || '';
    this.searchOptions.filterOptions = this.$route.query.filterOptions === 'true' ? true : false;
  }

  public visibleArticles(articles: Article[]) {
    return articles.filter((article: Article, index: number) => index < this.maxArticle);
  }

  public edit(item: OptionGroup) {
    if (this.$can(Permission.OPTION_UPDATE)) {
      this.$router.push({ name: 'option.edit', params: { id: item._id }, query: { ...this.$route.query } });
    } else {
      this.$router.push({ name: 'option.editSimple', params: { id: item._id }, query: { ...this.$route.query } });
    }
  }

  public canToggleVisibility(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_VISIBLE);
    }
    return this.$can(Permission.OPTION_HIDE);
  }

  public resetTable() {
    this.$refs.vTable.resetPagination();
  }

  public copyOption(id: string) {
    this.$store.dispatch('foodcard/copyOption', { id });
    this.notifyInfo('venue.notification.successfulСopy');
  }

  public handleOptionGroupCell(item: OptionGroup) {
    this.$emit('handleOptionGroupCell', item);
  }

  public async deleteSelectable() {
    const ids = this.$refs.vTable.getSelected().map((option) => option._id);

    if (ids.length) {
      this.$emit('deleteGroup', ids);
      this.$refs.vTable.resetSelected();
    }
    this.deleteSelectableDialog = false;
  }
}
